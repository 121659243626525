import { toolbox } from "./toolbox";

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

declare global {
  interface Window {
    Blockly: any;
  }
}

export const initBlockly = async () => {
  const Blockly = window.Blockly;
  const checkBlocklyInjected = document.getElementById("toolbox-categories");
  if (!checkBlocklyInjected) {
    Blockly.WorkspaceSvg.prototype.preloadAudio_ = function () {};
    //INFO : Compatibility of Scratch Blocks of Blockly Javascript Generator
    if (!Blockly.Variables.allUsedVarModels) {
      Blockly.Variables.allUsedVarModels = function (a: any) {
        var b = a.getAllBlocks(!1);
        a = Object.create(null);
        for (var c = 0; c < b.length; c++) {
          var d = b[c].getVarModels();
          if (d)
            for (var e = 0; e < d.length; e++) {
              var f = d[e];
              f.getId() && (a[f.getId()] = f);
            }
        }
        b = [];
        for (var g in a) b.push(a[g]);
        return b;
      };
    }
    const xmlTextNode = new DOMParser();
    const xmlContent = xmlTextNode.parseFromString(
      toolbox,
      "text/xml"
    ).documentElement;
    document.body.appendChild(xmlContent);

    await sleep(400);
  }
  const blocklyDiv = document.getElementById("blocklyDiv");
  const blocklyToolbox = document.getElementById("blocklyToolbox");

  Blockly.inject(blocklyDiv, {
    toolbox: blocklyToolbox,
    trashcan: true,
    media: "/media/",
    sounds: true,
    zoom: {
      controls: true,
      wheel: true,
      startScale: 0.75,
      maxScale: 4,
      minScale: 0.25,
      scaleSpeed: 1.1,
    },
  });
};

export const handleBlocklyResize = () => {
  let blocklyArea: any = document.getElementById("blocklyArea");
  const blocklyDiv = document.getElementById("blocklyDiv");
  if (blocklyArea && blocklyDiv) {
    let x = 0;
    let y = 0;
    do {
      x += blocklyArea.offsetLeft;
      y += blocklyArea.offsetTop;
      blocklyArea = blocklyArea.offsetParent;
    } while (blocklyArea);
    blocklyDiv.style.left = x + "px";
    blocklyDiv.style.top = y + "px";
    blocklyDiv.style.width = blocklyArea.offsetWidth + "px";
    blocklyDiv.style.height = blocklyArea.offsetHeight + "px";
  }
};
