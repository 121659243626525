import { Component } from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export interface TabsAttribute {
  name: string;
}

interface Props {
  tabs: TabsAttribute[];
  selectedValue: string;
  onChange: (value: string) => void;
}

export default class Tabs extends Component<Props> {
  onChange = (value: string) => {
    this.props.onChange(value);
  };

  render() {
    return (
      <>
        <div className="sm:hidden">
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          >
            {this.props.tabs.map((eachTab) => (
              <option key={eachTab.name}>{eachTab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-3" aria-label="Tabs">
            {this.props.tabs.map((tab) => (
              <div key={tab.name} onClick={this.onChange.bind(this, tab.name)}>
                <p
                  className={classNames(
                    tab.name === this.props.selectedValue
                      ? "bg-gray-200 text-gray-800"
                      : "text-gray-600 hover:text-gray-800",
                    "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                  )}
                  aria-current={
                    tab.name === this.props.selectedValue ? "page" : undefined
                  }
                >
                  {tab.name}
                </p>
              </div>
            ))}
          </nav>
        </div>
      </>
    );
  }
}
