import "aframe";
import "aframe-gradient-sky";

export const initAFRAMEScene = (debug?: boolean) => {
  return new Promise((resolve, reject) => {
    const ascene = document.createElement("a-scene");
    ascene.setAttribute("id", "ascene");
    ascene.setAttribute("fog", "type : linear; color : #AAA; far : 1000");
    ascene.setAttribute(
      "renderer",
      "antialias : true; colorManagement : true;"
    );
    ascene.className = "w-full h-full relative top-0 left-0";
    ascene.setAttribute("embedded", "");

    if (debug) {
      ascene.setAttribute("stats", "fps");
      ascene.setAttribute(
        "physics",
        "friction: 0.1; restitution: 0.5; debug: true"
      );
    }

    const sceneContainer = document.getElementById("sceneContainer");
    if (sceneContainer) {
      sceneContainer.appendChild(ascene);
      ascene.addEventListener("loaded", () => {
        resolve(null);
      });
    } else {
      reject(null);
    }
  });
};

export const initAFRAMESky = () => {
  const ascene = document.getElementById("ascene");
  if (ascene) {
    const gradientSky = document.createElement("a-sky");
    const topColor = handleHexToRGB("#e0f2fe");
    const bottomColor = handleHexToRGB("#f8fafc");
    gradientSky.setAttribute(
      "material",
      `shader: gradient; topColor: ${topColor.r} ${topColor.g} ${topColor.b} ; bottomColor: ${bottomColor.r} ${bottomColor.g} ${bottomColor.b};`
    );

    ascene.appendChild(gradientSky);
  }
};

const handleHexToRGB = (hex: string) => {
  const m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
  if (m) {
    return {
      r: parseInt(m[1], 16),
      g: parseInt(m[2], 16),
      b: parseInt(m[3], 16),
    };
  } else {
    return {
      r: 0,
      g: 0,
      b: 0,
    };
  }
};

export const initEditorControls = () => {
  const container = document.querySelector("a-scene") as any;
  if (container) {
    container.components.inspector.openInspector();
  }
};
