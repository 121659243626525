import React, { Component } from "react";
import Button from "../components/base/Button";
import Input from "../components/base/Input";
import { withRouter } from "../navigator/NavigateWrapper";
import Logo from "./../images/logo.png";

interface Props {
  navigate: (path: string) => {};
}

class Login extends Component<Props> {
  handleNavigate = () => {
    this.props.navigate("/");
  };

  render() {
    return (
      <>
        <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-12 w-auto" src={Logo} alt="CoBlix" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <Input />
                </div>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <Input />
                </div>
              </div>

              <div className="mt-8">
                <Button
                  text="Sign in"
                  type="normal"
                  className="w-full flex justify-center"
                  onClick={this.handleNavigate}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Login);
