import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Component, Fragment } from "react";
import { withRouter } from "../../navigator/NavigateWrapper";
import { getModelImagePreview } from "../../store/actions/modelAction";
import { addEntity } from "../../utils/inspector/object";
import Button from "../base/Button";
import Tabs, { TabsAttribute } from "../base/Tabs";

enum ObjectCategory {
  BUILDING = "building",
  CHARACTER = "character",
  ENVIRONMENT = "environment",
  PROP = "prop",
  VEHICLE = "vehicle",
  ANIMALS = "animals",
}
enum Theme {
  CITY = "city",
  DESERT = "desert",
  FOREST = "forest",
  ISLAND = "island",
}

const prefixPath = {
  building: ["city"],
  character: ["city"],
  environment: ["city", "desert", "forest", "island"],
  prop: ["city", "desert"],
  vehicle: ["city", "desert", "island"],
  animals: ["forest", "island"],
};

enum ControlType {
  OBJECT = "Object",
  CONTROLS = "Controls",
  PROPERTIES = "Properties",
}

const controlActions: TabsAttribute[] = [
  { name: "Move" },
  { name: "Rotate" },
  { name: "Scale" },
];

interface State {
  type: ControlType;
  controlActions: string;
  previewImgDataList: string[];
  selectedCategory: ObjectCategory | null;
  selectedTheme: Theme | null;
  loading: boolean;
}

interface Props {
  navigate: any;
  handleBlockyVisibile: () => void;
}

class Controls extends Component<Props> {
  state: State = {
    type: ControlType.OBJECT,
    controlActions: "Move",
    previewImgDataList: [],
    selectedCategory: null,
    selectedTheme: null,
    loading: false,
  };

  handleChangeObjectCategory = async (objectCategory: ObjectCategory) => {
    if (this.state.selectedCategory === objectCategory) {
      this.setState({
        selectedCategory: null,
        previewImgDataList: [],
      });
    } else {
      this.setState({
        selectedCategory: objectCategory,
        previewImgDataList: [],
        selectedTheme: prefixPath[objectCategory][0],
        loading: true,
      });
      const data = await getModelImagePreview(
        prefixPath[objectCategory][0],
        objectCategory
      );

      this.setState({
        previewImgDataList: data,
        loading: false,
      });
    }
  };

  handleChangeControlActions = (controlActions: string) => {
    this.setState({
      controlActions,
    });
  };

  handleChangeControlType = (type: ControlType) => {
    this.setState({
      type,
    });
  };

  handleChangeSelectedTheme = async (selectedTheme: Theme) => {
    this.setState({
      previewImgDataList: [],
      selectedTheme: selectedTheme,
      loading: true,
    });

    if (this.state.selectedCategory) {
      const data = await getModelImagePreview(
        selectedTheme,
        this.state.selectedCategory
      );

      this.setState({
        previewImgDataList: data,
        loading: false,
      });
    }
  };

  handleNavigateDemo = () => {
    this.props.navigate("/demo");
  };

  handleAddEntity = (modelUrl: string, pathName: string) => {
    let size = 1;
    if (pathName.includes("_Animals")) {
      size = 0.3;
    } else if (
      pathName.includes("City_Bld") ||
      pathName.includes("City_Env") ||
      pathName.includes("City_Prop")
    ) {
      size = 70;
    } else if (pathName.includes("City_Car")) {
      size = 0.5;
    }

    addEntity(
      "model",
      "0,0,0",
      "0,0,0",
      { url: modelUrl, size: size, id: pathName },
      true
    );
    if (this.state.selectedCategory) {
      this.handleChangeObjectCategory(this.state.selectedCategory);
    }
  };

  renderObjectCategory = (objectCategory: ObjectCategory) => {
    return (
      <span
        className="inline-flex justify-center w-full rounded-md capitalize py-2 text-sm font-medium text-gray-700 cursor-pointer"
        onClick={this.handleChangeObjectCategory.bind(this, objectCategory)}
      >
        {objectCategory}
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </span>
    );
  };

  renderControlType = (type: ControlType) => {
    let defaultCss =
      "text-gray-900 inline-flex items-center border-b-2 text-sm font-bold cursor-pointer ";
    if (this.state.type === type) {
      defaultCss += "border-sky-600 pt-1 pb-1";
    } else {
      defaultCss += "border-transparent";
    }
    return (
      <p
        className={defaultCss}
        onClick={this.handleChangeControlType.bind(this, type)}
      >
        {type}
      </p>
    );
  };

  renderObjectThemeCategory = (objectCategory: ObjectCategory) => {
    let objectThemeList: JSX.Element[] = [];

    prefixPath[objectCategory].map((eachPrefixPath: any) => {
      let defaultCss =
        "text-gray-900 inline-flex items-center border-b-2 text-sm font-bold cursor-pointer capitalize ";
      if (this.state.selectedTheme === eachPrefixPath) {
        defaultCss += "border-sky-600 pt-1 pb-1";
      } else {
        defaultCss += "border-transparent";
      }
      objectThemeList.push(
        <p
          key={eachPrefixPath}
          className={defaultCss}
          onClick={this.handleChangeSelectedTheme.bind(this, eachPrefixPath)}
        >
          {eachPrefixPath}
        </p>
      );
      return null;
    });
    return objectThemeList;
  };

  renderObjectContent = (objectCategory: ObjectCategory) => {
    return (
      <Menu as="div" className="relative inline-block text-left z-20">
        {this.renderObjectCategory(objectCategory)}
        <Transition
          show={this.state.selectedCategory === objectCategory ? true : false}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-left absolute left-0 mt-2 w-96 h-96 overflow-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="mx-auto px-4 py-6 sm:px-6 lg:px-8 flex flex-row w-full h-8 items-center space-x-4">
              {this.renderObjectThemeCategory(objectCategory)}
            </div>

            {this.state.loading ? (
              <div className="w-full h-5/6 flex items-center justify-center">
                <div>
                  <div className="mx-auto flex items-center justify-center h-10 w-10 rounded-full border-4 border-t-4 border-sky-100 loading-spinner" />
                  <div className="mt-3 text-center sm:mt-5">
                    <h3
                      className="text-sm leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Loading
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mx-auto px-4 py-2 sm:px-6 lg:px-8">
                <ul className="grid gap-x-4 gap-y-4 grid-cols-2">
                  {this.renderObject(objectCategory)}
                </ul>
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  renderControlContent = () => {
    switch (this.state.type) {
      case ControlType.OBJECT:
        return (
          <>
            {this.renderObjectContent(ObjectCategory.CHARACTER)}
            {this.renderObjectContent(ObjectCategory.ANIMALS)}
            {this.renderObjectContent(ObjectCategory.BUILDING)}
            {this.renderObjectContent(ObjectCategory.ENVIRONMENT)}
            {this.renderObjectContent(ObjectCategory.PROP)}
            {this.renderObjectContent(ObjectCategory.VEHICLE)}
          </>
        );
      case ControlType.CONTROLS:
        return (
          <div>
            <Tabs
              tabs={controlActions}
              selectedValue={this.state.controlActions}
              onChange={this.handleChangeControlActions}
            />
          </div>
        );
    }
  };

  renderObject = (objectCategory: ObjectCategory) => {
    const filesElementList: JSX.Element[] = [];

    this.state.previewImgDataList.map((eachResult: any) => {
      let path = eachResult.Key.split("/");
      let length = path.length;
      path = path[length - 1];

      let name = "";

      let pathName = path.split(".");
      pathName = pathName[0];

      let imageUrl = `https://coblixassets.s3.ap-southeast-1.amazonaws.com/${this.state.selectedTheme}/image/${objectCategory}/${pathName}.jpg`;
      let modelUrl = `https://coblixassets.s3.ap-southeast-1.amazonaws.com/${this.state.selectedTheme}/model/${objectCategory}/${pathName}.glb`;

      if (objectCategory === "character") {
        name = pathName.replace("City_Character_", "");
      }
      if (objectCategory === "animals") {
        if (pathName.includes("Forest_Animals")) {
          name = pathName.replace("Forest_Animals_", "");
        } else {
          name = pathName.replace("Island_Aquatic_Animals_", "");
        }
      } else if (objectCategory === "building") {
        name = pathName.replace("City_Bld_", "");
      } else if (objectCategory === "vehicle") {
        if (pathName.includes("City_")) {
          name = pathName.replace("City_Car_", "");
        } else if (pathName.includes("Island_")) {
          name = pathName.replace("Island_Vehicle", "");
        } else {
          name = pathName.replace("Desert_Raft", "");
        }
      } else if (objectCategory === "environment") {
        if (pathName.includes("SM_Env_")) {
          name = pathName.replace("SM_Env_", "");
        } else if (pathName.includes("Desert_Raft", "")) {
          name = pathName.replace("Desert_Raft_Env", "");
        } else if (pathName.includes("Forest_Env", "")) {
          name = pathName.replace("Forest_Env", "");
        } else if (pathName.includes("Island_Env_", "")) {
          name = pathName.replace("Island_Env_", "");
        } else {
          name = pathName.replace("City_Env_", "");
        }
      } else if (objectCategory === "prop") {
        if (pathName.includes("City_Prop_")) {
          name = pathName.replace("City_Prop_", "");
        } else {
          name = pathName.replace("Desert_Raft_Prop", "");
        }
      }

      name = name.replaceAll("_", " ");

      filesElementList.push(
        <li
          key={imageUrl}
          className="relative"
          onClick={this.handleAddEntity.bind(this, modelUrl, pathName)}
        >
          <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-sky-600 overflow-hidden">
            <img
              src={imageUrl}
              alt=""
              className="object-cover pointer-events-auto cursor-pointer group-hover:opacity-75"
            />
          </div>
          <p className="mt-2 block text-sm font-medium text-gray-500 pointer-events-none">
            {name}
          </p>
        </li>
      );
      return null;
    });
    return filesElementList;
  };

  render() {
    return (
      <>
        <div className="flex flex-row w-full h-6 justify-center items-center space-x-4">
          {this.renderControlType(ControlType.OBJECT)}
          {this.renderControlType(ControlType.CONTROLS)}
          <div className="flex-1" />
          <Button
            type="normal"
            text="Code"
            className="w-20 flex h-full justify-center items-center"
            onClick={this.props.handleBlockyVisibile}
          />
          <Button
            type="normal"
            text="Play"
            className="w-20 flex h-full justify-center items-center"
            onClick={this.handleNavigateDemo}
          />
        </div>

        <div className="flex flex-row w-full items-center space-x-6 mt-2">
          {this.renderControlContent()}
        </div>
      </>
    );
  }
}

export default withRouter(Controls);
