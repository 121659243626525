import { PencilAltIcon } from "@heroicons/react/outline";
import React, { Component } from "react";
import { withRouter } from "../navigator/NavigateWrapper";
import { initAFRAMEScene, initAFRAMESky } from "../utils/inspector";
import { addEntity } from "../utils/inspector/object";

interface Props {
  navigate: any;
}

class Demo extends Component<Props> {
  componentDidMount = async () => {
    await initAFRAMEScene();
    initAFRAMESky();
    for (let j = -25; j < 25; j = j + 5) {
      for (let i = -25; i < 25; i = i + 5) {
        if (i % 5 === 0 && j === -5) {
          addEntity("model", `${i + 5} 0 -5`, "0 90 0", {
            url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/city/model/environment/City_Env_Road_Lines_02.glb",
            id: "City_Env_Road_Lines_02",
            size: 100,
          });
        } else
          addEntity("model", `${i} 0 ${j}`, "0 0 0", {
            url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/city/model/environment/City_Env_Grass_01.glb",
            id: "City_Grass",
            size: 100,
          });
      }
    }

    addEntity("animatedModel", "-5 0 -12", "0 -110 0", {
      url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/city/model/character/City_Character_Male_Jacket.glb",
      id: "City_Character_Male_Jacket",
      animation: "Walking",
      size: 2,
    });

    addEntity("animatedModel", "1 0 -10", "0 90 0", {
      url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/city/model/character/City_Character_Female_Jacket.glb",
      id: "City_Character_Female_Jacket",
      animation: "Walking",
      size: 2,
    });

    addEntity("model", "10 0 -10", "0 -90 0", {
      url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/forest/model/animals/Forest_Animals_Cow.glb",
      id: "Forest_Animals_Cow",
      size: 0.5,
    });

    addEntity("model", "6 0 -10", "0 -45 0", {
      url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/forest/model/animals/Forest_Animals_Cow.glb",
      id: "Forest_Animals_Cow",
      size: 0.2,
    });

    addEntity("model", "5 0 -10", "0 -30 0", {
      url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/forest/model/animals/Forest_Animals_Cow.glb",
      id: "Forest_Animals_Cow",
      size: 0.2,
    });

    addEntity("model", "7 0 -12", "0 50 0", {
      url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/forest/model/animals/Forest_Animals_Cow.glb",
      id: "Forest_Animals_Cow",
      size: 0.2,
    });

    addEntity("model", "-15 0 -7", "0 90 0", {
      url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/city/model/vehicle/City_Car_Police Car.glb",
      id: "City_Car_Police",
      size: 1.5,
    });

    for (let j = -20; j < -15; j = j + 2) {
      for (let i = -20; i < 0; i = i + 2) {
        addEntity(
          "model",
          `${i} 0 ${j}`,
          `0 ${Math.floor(Math.random() * (360 - -360 + 1) + -360)} 0`,
          {
            url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/forest/model/animals/Forest_Animals_Sheep.glb",
            id: "Forest_Animals_Sheep",
            size: i % 3 === 0 ? 0.3 : 0.4,
          }
        );
      }
    }

    for (let j = 0; j < 20; j = j + 4) {
      for (let i = -20; i < 0; i = i + 4) {
        addEntity(
          "model",
          `${i} 0 ${j}`,
          `0 ${Math.floor(Math.random() * (360 - -360 + 1) + -360)} 0`,
          {
            url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/forest/model/animals/Forest_Animals_Horse.glb",
            id: "Forest_Animals_Horse",
            size: i % 3 === 0 ? 0.3 : 0.4,
          }
        );
      }
    }

    for (let j = 1; j < 20; j = j + 2.5) {
      for (let i = 1; i < 20; i = i + 2.5) {
        addEntity(
          "model",
          `${i} 0 ${j}`,
          `0 ${Math.floor(Math.random() * (360 - -360 + 1) + -360)} 0`,
          {
            url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/forest/model/animals/Forest_Animals_Llama.glb",
            id: "Forest_Animals_Llama",
            size: i % 3 === 0 ? 0.5 : 0.4,
          }
        );
      }
    }

    for (let j = -26; j < -10; j = j + 2) {
      for (let i = 0; i < 20; i = i + 2) {
        addEntity(
          "model",
          `${i} 0 ${j}`,
          `0 ${Math.floor(Math.random() * (360 - -360 + 1) + -360)} 0`,
          {
            url: "https://coblixassets.s3.ap-southeast-1.amazonaws.com/forest/model/environment/Forest_Env_CommonTree_3.glb",
            id: "Forest_Env_CommonTree_3",
            size: i % 3 === 0 ? 1.5 : 2.5,
          }
        );
      }
    }
  };

  handleNavigateEditor = () => {
    this.props.navigate("/editor");
    window.location.reload();
  };

  render() {
    return (
      <div className="h-screen w-screen">
        <div id="sceneContainer" className="h-full w-full" />
        <div
          className="h-10 w-10 bg-sky-600 absolute top-3 left-3 z-20 cursor-pointer rounded-full flex items-center justify-center hover:opacity-90"
          onClick={this.handleNavigateEditor}
        >
          <PencilAltIcon className="text-white h-6 w-6" />
        </div>
      </div>
    );
  }
}

export default withRouter(Demo);
