import React, { Component } from "react";
import Header from "../components/base/Header";
import Home from "../components/dashboard/Home";
import { withRouter } from "../navigator/NavigateWrapper";

interface Props {
  navigate: any;
}

class Dashboard extends Component<Props> {
  render() {
    return (
      <div className="h-screen flex overflow-hidden bg-gray-50">
        <div className="flex-1 overflow-auto focus:outline-none">
          <Header page="Dashboard" />
          <header className="bg-white shadow">
            <div className="max-w-7xl h-12 mx-auto py-4 px-4 sm:px-6 flex items-center lg:px-8">
              <h1 className="text-md font-bold text-gray-900">
                Explore Your Universe
              </h1>
            </div>
          </header>
          <main>
            <Home navigate={this.props.navigate} />
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
