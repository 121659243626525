import { ListObjectsCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

const s3 = new S3Client({
  region: "ap-southeast-1",
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: "ap-southeast-1" },
    identityPoolId: "ap-southeast-1:eeaadc37-ec30-4b66-aea9-bf09f49587a5",
  }),
});

export const getModelImagePreview = async (theme: string, category: string) => {
  try {
    let getParams = {
      Bucket: "coblixassets",
      Prefix: `${theme}/image/${category}`,
    };

    const command = new ListObjectsCommand(getParams);
    const data = await s3.send(command);
    if (data.Contents) {
      return data.Contents;
    } else {
      return "Unknown Error";
    }
  } catch (err) {
    return err;
  }
};
