import React, { Component } from "react";
import Header from "../components/base/Header";
import Controls from "../components/dashboard/Controls";
import { handleBlocklyResize, initBlockly } from "../utils/blockly";
import {
  initAFRAMEScene,
  initAFRAMESky,
  initEditorControls,
} from "../utils/inspector";

interface State {
  blockyVisible: boolean;
}

class Editor extends Component {
  state: State = {
    blockyVisible: true,
  };

  componentDidMount = async () => {
    try {
      await initBlockly();
      this.handleBlockyVisibile();
      window.addEventListener("resize", this.handleBlocklyResize);
      await initAFRAMEScene();
      initAFRAMESky();
      initEditorControls();
    } catch (err) {}
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleBlocklyResize);
  };

  handleBlocklyResize = () => {
    handleBlocklyResize();
  };

  handleBlockyVisibile = () => {
    const blockyVisible = !this.state.blockyVisible;
    this.setState({
      blockyVisible,
    });
  };

  render() {
    const blocklyHeight = window.innerHeight - 136;
    return (
      <div className="h-screen flex flex-col overflow-hidden bg-gray-50">
        <Header />
        <header className="bg-white shadow">
          <div className="max-w-7xl h-20 mx-auto py-2 px-4 sm:px-6 lg:px-8">
            <Controls handleBlockyVisibile={this.handleBlockyVisibile} />
          </div>
        </header>
        <main className="w-full" style={{ height: blocklyHeight }}>
          <div
            id="blocklyArea"
            className={`absolute h-full w-10/12 z-10 right-0 md:w-7/12 ${
              this.state.blockyVisible ? "block" : "hidden"
            } `}
            style={{ height: blocklyHeight }}
          >
            <div id="blocklyDiv" className="h-full" />
          </div>
          <div id="sceneContainer" className="h-full w-full" />
        </main>
      </div>
    );
  }
}

export default Editor;
