import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import React, { Component, Fragment } from "react";
import { withRouter } from "../../navigator/NavigateWrapper";
import WhiteLogo from "./../../images/coblix_white_logo.png";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [{ name: "Dashboard", href: "/" }];
const userNavigation = [{ name: "Sign out", href: "/auth" }];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  navigate: any;
  page: string;
}

class Header extends Component<Props> {
  handleNavigation = (path: string) => {
    this.props.navigate(path);
  };

  render() {
    return (
      <Disclosure as="nav" className="bg-sky-600">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-14">
                <div className="flex">
                  <div
                    className="flex-shrink-0 flex items-center cursor-pointer"
                    onClick={this.handleNavigation.bind(this, "/")}
                  >
                    <img
                      className="block h-8 w-auto"
                      src={WhiteLogo}
                      alt="White_Logo"
                    />
                  </div>
                  <div className="hidden flex items-center sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item) => (
                      <span
                        key={item.name}
                        onClick={this.handleNavigation.bind(this, item.href)}
                        className={classNames(
                          this.props.page === item.name
                            ? "border-white pt-1 pb-1"
                            : "border-transparent",
                          "cursor-pointer text-white inline-flex items-center px-1 border-b-2 text-sm font-medium"
                        )}
                        aria-current={
                          this.props.page === item.name ? "page" : undefined
                        }
                      >
                        {item.name}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.imageUrl}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <p
                                onClick={this.handleNavigation.bind(
                                  this,
                                  item.href
                                )}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm font-bold text-blue-600"
                                )}
                              >
                                {item.name}
                              </p>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:ring-2 hover:ring-white focus:outline-none focus:ring-2 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="bg-white border-b border-gray drop-shadow sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="p"
                    onClick={this.handleNavigation.bind(this, item.href)}
                    className={classNames(
                      this.props.page === item.name
                        ? "border-blue-600"
                        : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                    aria-current={
                      this.props.page === item.name ? "page" : undefined
                    }
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium text-gray-600">
                      {user.email}
                    </div>
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="p"
                      onClick={this.handleNavigation.bind(this, item.href)}
                      className="block px-4 py-2 text-base font-medium text-gray-900 hover:text-gray-800 hover:bg-gray-100"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }
}

export default withRouter(Header);
