import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Demo from "../pages/Demo";
import Editor from "../pages/Editor";
import Login from "../pages/Login";

class Navigator extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/auth" element={<Login />} />
          <Route path="/editor*" element={<Editor />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default Navigator;
