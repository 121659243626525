import { TextureLoader } from "three";
import CityTexture from "../../images/textures/city_texture.png";
import DesertTexture from "../../images/textures/desert_texture.png";

interface Model {
  url: string;
  id: string;
  size: number;
  animation?: string;
}

export const addEntity = (
  type: string,
  position: string,
  rotation: string,
  model?: Model,
  editor?: boolean
) => {
  let componentDefinition;
  if (type === "model") {
    componentDefinition = {
      element: "a-entity",
      attributes: {
        position: position,
        rotation: rotation,
        "gltf-model": model?.url,
        scale: `${model?.size} ${model?.size} ${model?.size}`,
      },
    };
  } else if (type === "animatedModel") {
    componentDefinition = {
      element: "a-entity",
      attributes: {
        position: position,
        rotation: rotation,
        "gltf-model": model?.url,
        scale: `${model?.size} ${model?.size} ${model?.size}`,
        "animation-mixer": `clip: ${model?.animation}`,
      },
    };
  } else if (type === "plane") {
    componentDefinition = {
      element: "a-plane",
      attributes: {
        type: "plane",
        position: position,
        rotation: "-90 0 0",
        scale: "1 1 1",
        color: "#FF3366",
        opacity: 1,
        height: 4,
        width: 4,
        texture: "none",
      },
    };
  } else if (type === "box") {
    componentDefinition = {
      element: "a-box",
      attributes: {
        position: position,
        color: "#8FBFE0",
        rotation: "0 0 0",
        scale: "1 1 1",
        depth: "2",
        height: "2",
        width: "2",
        opacity: 1,
        type: "box",
      },
    };
  } else if (type === "cone") {
    componentDefinition = {
      element: "a-cone",
      attributes: {
        position: position,
        color: "#FF3366",
        rotation: "0 0 0",
        scale: "1 1 1",
        "radius-bottom": "1.5",
        "radius-top": "0.5",
        height: 2,
        opacity: 1,
        type: "cone",
      },
    };
  } else if (type === "cylinder") {
    componentDefinition = {
      element: "a-cylinder",
      attributes: {
        position: position,
        color: "#2EC4B6",
        rotation: "0 0 0",
        scale: "1 1 1",
        height: "2",
        radius: "1",
        opacity: 1,
        type: "cylinder",
      },
    };
  } else if (type === "sphere") {
    componentDefinition = {
      element: "a-sphere",
      attributes: {
        position: position,
        color: "#14BDEB",
        rotation: "0 0 0",
        scale: "1 1 1",
        radius: "1",
        opacity: 1,
        type: "sphere",
      },
    };
  }
  if (componentDefinition) {
    createNewEntity(componentDefinition, model, editor);
  }
};

const createNewEntity = (
  definition: { element: string; attributes: any },
  model?: Model,
  editor?: boolean
) => {
  const sceneEl = AFRAME.scenes[0];
  if (sceneEl) {
    const entity = document.createElement(definition.element);
    for (let attr in definition.attributes) {
      entity.setAttribute(attr, definition.attributes[attr]);
    }
    if (editor) {
      entity.addEventListener("loaded", () => {
        entity.pause();
      });
    }
    if (model) {
      if (model.id.includes("City_") || model.id.includes("Desert_")) {
        entity.addEventListener("model-loaded", (event: any) => {
          let tex: any = "";
          if (model.id.includes("City_")) {
            tex = new TextureLoader().load(CityTexture);
          } else {
            tex = new TextureLoader().load(DesertTexture);
          }
          tex.flipY = false;
          event.detail.model.traverse(function (node: any) {
            if (node.isMesh) {
              node.material.map = tex;
            }
          });
        });
      }
    }
    sceneEl.appendChild(entity);
  }
};
